import React, { useState, useEffect } from "react";
import "./addBanner.css";
import { fetchProductsById, updateProductById } from "../../../api/productApi"; // Assume these APIs are implemented
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../modal/Modal";
import { FaCheckCircle } from "react-icons/fa"; // Import the success icon

export const UpdateBanner = (props) => {
  const { id: productId, closeModal } = props;
  const [preview, setPreview] = useState([]);
  const [productData, setBannerData] = useState({
    name: "",
    description: "",
    image: null, // Single image
    retainedImage: "", // Single retained image
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    // Fetch product details when component mounts
    const fetchProduct = async () => {
      const response = await fetchProductsById(productId,'banners'); // Fetch existing product details
      console.log(response);
      setBannerData({
        name: response.data.name,
        description: response.data.description,
        image: null, // Image will be managed separately
        retainedImage: response.data.image || "", // Single retained image
      });
      setPreview(response.data.previewImage || ""); // Display existing image
    };

    fetchProduct();
  }, [productId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBannerData({ ...productData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0]; // Single file
    setBannerData({
      ...productData,
      image: file,
    });

    // Generate preview URL for the new file
    const newPreview = URL.createObjectURL(file);
    setPreview(newPreview);
  };

  const handleImageDelete = () => {
    setBannerData({ ...productData, image: null, retainedImage: "" });
    setPreview(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append the single file to FormData
    if (productData.image) {
      formData.append("image", productData.image);
    }

    formData.append("name", productData.name);
    formData.append("description", productData.description);
    formData.append("retainedImage", productData.retainedImage); // Single retained image

    let response = await updateProductById(productId, formData, "banners"); // Update API call
    if (response.data.statusCode === 200) {
      setModalOpen(true);
      setModalContent("success");
    } else {
      setModalOpen(true);
      setModalContent("failure");
    }
  };

  const functionForhandleModal = () => {
    setModalOpen(true);
    closeModal();
  };

  return (
    <div className="product-entry-container">
      <h2 className="product-entry-title">Update Banner</h2>
      <form onSubmit={handleSubmit} className="product-entry-form">
        <div className="form-group">
          <input
            type="text"
            id="name"
            name="name"
            value={productData.name}
            onChange={handleChange}
            required
            placeholder="Enter product name"
          />
        </div>
        <div className="form-group">
          <textarea
            id="description"
            name="description"
            value={productData.description}
            onChange={handleChange}
            required
            placeholder="Enter product description"
          ></textarea>
        </div>

        <div className="form-group">
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImageUpload}
            required
          />

          {/* Display preview with delete options */}
          <div className="preview-container">
              <div  className="preview-item">
                <img
                  src={preview}
                  alt={`Preview`}
                  className="image-preview"
                  style={{ width: "100px", height: "100px", margin: "10px" }}
                />

                <IconButton
                  className="delete-button"
                  onClick={() =>
                    handleImageDelete()
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </div>
         
          </div>
        </div>
        <button type="submit" className="submit-button submit-button-add">
          Update Banner
        </button>
      </form>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="close-btn"></div>

        {modalContent == "success" && (
          <>
            <div className="success-alert">
              <FaCheckCircle className="success-icon" />
              <p className="success-message">Success</p>
            </div>
            <button onClick={functionForhandleModal} className="success-btn-update">
              Ok
            </button>
          </>
        ) }
  
        {modalContent == "failure" && (
          <>
            <div className="success-alert">
              <FaCheckCircle className="failure-icon" />
              <p className="failure-message">Failure</p>
            </div>
            <button onClick={functionForhandleModal} className="failure-btn">
              Ok
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};
