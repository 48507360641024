import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import logo_without_bg from "../../assets/cleardew_logo_removebg.png"; // Adjust based on your project structure
import { FaEnvelope, FaPhoneAlt, FaBars, FaTimes } from "react-icons/fa"; // Adding FaBars for hamburger icon


const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-content">
        {/* Hamburger Icon for Mobile */}
        <div className="hamburger-icon" onClick={toggleMenu}>
          {menuOpen ? (
            <FaTimes aria-label="Close Menu" />
          ) : (
            <FaBars aria-label="Open Menu" />
          )}
        </div>

        {/* Logo Box */}
        <div className="logo-box">
          <img src={logo_without_bg} alt="Cleardew Logo" />
        </div>

        {/* Menu Links (Toggle visibility based on menuOpen state) */}
        <nav className={`menu-box ${menuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/" onClick={toggleMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={toggleMenu}>
                About
              </Link>
            </li>
            <li>
              <Link to="/products" onClick={toggleMenu}>
                Products
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toggleMenu}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>

        {/* Email Box */}
        <div className={`email-box ${menuOpen ? "open" : ""}`}>
          <div className="email-details">
            <p>
              <FaEnvelope aria-label="Email icon" /> sales@cleardew.in
            </p>
            <p>
              <FaPhoneAlt aria-label="Phone icon" /> +91 9994944393
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
