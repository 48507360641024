import React from 'react';
import './weserve.css'; // Import the corresponding CSS file
import { FaHospital, FaSchool, FaShoppingCart, FaChurch, FaHotel,FaBuilding,FaIndustry } from 'react-icons/fa'; // Import icons from react-icons

const industries = [
  {
    id: 1,
    icon: <FaBuilding />, // Add the appropriate icon for Office Building
    title: 'Office Buildings',
    description: 'Offering comprehensive solutions for office buildings, ensuring a clean and efficient working environment.',
  },
  {
    id: 2,
    icon: <FaIndustry />, // Add the appropriate icon for Industries and Manufacturing
    title: 'Industries and Manufacturing',
    description: 'Providing industrial-grade products and services to enhance manufacturing efficiency and safety.',
  },
  {
    id: 3,
    icon: <FaSchool />,
    title: 'Education',
    description: 'Supporting educational institutions with quality materials and services tailored to their needs.',
  },
  {
    id: 4,
    icon: <FaHospital />,
    title: 'Hospitals',
    description: 'Providing specialized products and services to meet the rigorous standards of healthcare facilities.',
  },
  {
    id: 5,
    icon: <FaHotel />,
    title: 'Hotels & Restaurants',
    description: 'Providing top-notch products and services for the hospitality industry, ensuring a great customer experience.',
  },
  {
    id: 6,
    icon: <FaShoppingCart />, // Add the appropriate icon for Retail Malls and Cinema Theaters
    title: 'Retail Malls & Cinema Theaters',
    description: 'Delivering high-quality solutions for retail malls and cinema theaters, enhancing customer experience and safety.',
  },
  {
    id: 7,
    icon: <FaChurch />, // Add the appropriate icon for Place of Worship
    title: 'POW - Place of Worship',
    description: 'Offering specialized products and services to maintain cleanliness and comfort in places of worship.',
  },
  {
    id: 7,
    icon: <FaHotel />, // Use an appropriate icon related to Facility and Management
    title: 'Facility and Management',
    description: 'Providing products and services designed to ensure efficient facility maintenance and management.',
  }
];


const IndustriesWeServe = () => {
  return (
    <section className="industries-we-serve">
      <div className="firstspan titleWeight">Industries We Serve</div>
      <div className="industries-list">
        {industries.map(industry => (
          <div key={industry.id} className="industry-card">
            <div className="industry-icon">{industry.icon}</div>
            <span className='secoundspan'>{industry.title}</span>
            <p>{industry.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default IndustriesWeServe;
