import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from "./components/home/home";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import AboutUs from "./components/about/About";
import ContactUs from "./components/contact/Contact";
import CardPage from "./components/product/ProductList";
import {AddProduct} from "./components/admin/product/AddProduct";
import {UpdateProduct} from "./components/admin/product/updateProduct";
import {UpdateBanner} from "./components/admin/banner/updateBanner";
import Login from "./components/admin/login/Login";
import Dashboard from "./components/admin/dashboard/Dashboard";

const App = () => {
  return (
    <div className="main">
      <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/products" element={<CardPage />} />
          <Route path="/addProduct" element={<AddProduct />} />
          <Route path="/updateProduct/:id" element={<UpdateProduct />} />
          <Route path="/updateBanner/:id" element={<UpdateBanner />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        <Footer />
      </Router>

    </div>
  );
};

export default App;
