import React from 'react';
import './home.css';
import SliderComponent from './slider/slider';
import ProductSlider from './productslider/productslider';
import AboutUs from './about/about';
import OurVisionMission from './ourVisionMission/ourvisionMission';
import IndustriesWeServe from './weserve/weserve';


const Home = () => {
  return (
    <>
    <SliderComponent/>
    <AboutUs/>
    <ProductSlider/>
    <OurVisionMission/>
    <IndustriesWeServe/>
    {/* <Clients/> */}
    </>
  );
};

export default Home;
