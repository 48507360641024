import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css'; // Ensure you have this CSS file for responsive images
import banner2 from '../../../assets/cleardew_banner_small.jpg';

const Slideshow = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows:false,
  };

  return (
    <section className='homeSlider'>
<div className='slider-wrapper'>
    <Slider {...settings}>
      <div>
      <img src={banner2} alt="Slide 1" />
      </div>
    </Slider>
    </div>
    </section>
  );
};

export default Slideshow;
