import React from 'react';
import Slider from 'react-slick';
import './productslider.css'; // Ensure this file contains your custom styles
import { products } from '../../product/ProductList';



const ProductSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <section className="product-slider titleWeight">
      <p>Our Products</p>
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} />
            {/* <div className="product-info">
              <h3>{product.name}</h3>
              <button className="card-button" onClick={()=>{}}>Learn More</button>
            </div> */}
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default ProductSlider;
