import React from "react";
import "./aboutus.css"; // Import the CSS for styling
import imageAboutus from "../../assets/about_contactus_top.jpg";
import imageForOurValues from "../../assets/our_value.jpg";

// Importing Icons from react-icons/fa
import {
  FaLeaf,
  FaIndustry,
  FaHandshake,
  FaBalanceScale,
} from "react-icons/fa";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      {/* Top Section - Full Width Image */}

      <section className="top-section">
        <div className="content-overlay">
          <p>About Us</p>
        </div>
      </section>

      {/* <div className='ourproduct-img-content titleWeight'>
        <p>AboutUs</p>
      </div>
      <div className="top-image-aboutus">
        <img src={topImage} alt="Top Banner" />
      </div> */}

      {/* Middle Section - Left Image, Right Content */}
      <section className="middle-section">
        <div className="middle-section-content">
          <div className="about-title">Our Company</div>
          <p>
            Clear Dew Hygiene is committed to meeting your requirements by
            offering solutions for all of your difficult and unique needs. With
            over ten years of expertise, we are elevating India's cleaning
            standards. By devoting time and resources in comprehending the
            Indian market, Clear Dew has created fresh approaches that simplify
            daily living. Each and every Clear Dew customer may be proud that
            our production process and products have little to no environmental
            effect.
          </p>
          <p>
           By going beyond the ordinary for our customers, Clear Dew pledges to
            deliver the best business cleaning services possible. Our goal is to
            establish and preserve affluent, pleasurable spaces that foster
            excellence in performance. Our success is based on our capacity to
            view our clients as people, not just as statistics, prospects, or
            jobs. We place a high importance on the people aspect of our brand.
            Our dedicated team of professionals is attempting to help various
            sectors. Our goods range from industries to educational
            institutions, health care, hotels, office buildings, malls, and so
            on. We are excited to collaborate with each and every one of you.
          </p>
        </div>
        <div className="middle-section-image">
          <img src={imageAboutus} alt="Team" className="middle-image" />
        </div>
      </section>

      {/* Bottom Section - Left Content (Our Values), Right Image */}
      <section className="bottom-section">
        <div className="bottom-section-image">
          <img src={imageForOurValues} alt="Values" className="bottom-image" />
        </div>
        <div className="bottom-section-content">
          <div className="bottom-section-content-sub">
          <div className="ourvalues">Our Values</div>
          <div className="section1">
            <div className="title">
              Quality<span className="line-aboutus">|</span>
              <FaIndustry className="iconforabouts" />
            </div>
            <div className="session-content">
              We manufacture the necessary merchandise to comply with market
              demands.
            </div>
          </div>

          <div className="section2">
            <div className="title">
              Sustainability<span className="line-aboutus">|</span>
              <FaLeaf className="iconforabouts" />
            </div>
            <div className="session-content">
              We are developing innovative formulations to ensure a sustainable
              environment.
            </div>
          </div>

          <div className="section3">
            <div className="title">
              Integrity<span className="line-aboutus">|</span>
              <FaBalanceScale className="iconforabouts" />
            </div>
            <div className="session-content">
              We demand the greatest levels of responsibility from ourselves. At
              Clear Dew, we endeavor to behave with honesty and transparency,
              and to embody the words we preach.
            </div>
          </div>

          <div className="section4">
            <div className="title">
              Partnership<span className="line-aboutus">|</span>
              <FaHandshake className="iconforabouts" />
            </div>
            <div className="session-content">
              Our partnership with distributors and consumers ensures timely
              service and success.
            </div>
          </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
