// AboutPage.js
import React from "react";
import "./ourvisionMission.css"; // Ensure to import the CSS file

const OurVisionMission = () => {
  return (
    <>
      <div class="container-visionmission">
        <div class="content-visionmission">
          <div className="vision-content titleWeight">
            <div>Our Vision</div>
            <p>
              We are dedicated to providing exceptional technical assistance
              paired with premium sanitation and cleaning solutions. Our focus
              is on delivering high-quality, tailored services that meet the
              unique needs of our clients. With our expertise, we ensure
              top-tier cleanliness and functionality in every environment.
            </p>
          </div>
        </div>
        <div class="content-visionmission">
          <div className="mission-content titleWeight">
            <div>Our Mission</div>
            <p>
              Being the most prominent manufacturer of disinfecting and cleaning
              products will enable us to make the environment safer and cleaner
              while also promoting the expansion and advancement of our
              partners, customers and staff.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurVisionMission;
