import React, { useState } from 'react';
import {
  APIProvider,
  InfoWindow,
  Map,
  Marker
} from '@vis.gl/react-google-maps';

const API_KEY = process.env.REACT_APP_MAP_KEY;

const position = {
  lat: 12.704496412461586,
  lng: 77.81662289454925,
};

const MapLocation = () => {
  // State to manage InfoWindow visibility
  const [isInfoWindowVisible, setInfoWindowVisible] = useState(false);

  // Function to handle marker click
  const handleMarkerClick = () => {
    setInfoWindowVisible(!isInfoWindowVisible); // Toggle InfoWindow visibility
  };

    // Function to handle marker click
    const handleMarkerClose = () => {
      setInfoWindowVisible(!isInfoWindowVisible); // Toggle InfoWindow visibility
    };

  return (
    <APIProvider apiKey={API_KEY} libraries={['marker']}>
      <Map
        mapId={'bf51a910020fa25a'}
        defaultZoom={12}
        defaultCenter={position}
        gestureHandling={'greedy'}
        disableDefaultUI>
        
        {/* simple marker with onClick event */}
        <Marker
          position={position}
          title={'CLEARDEW HYGIENE - Cleaning products supplier'}
          onClick={handleMarkerClick} // Show InfoWindow on click
        />

        {/* Conditionally render InfoWindow based on state */}
        {isInfoWindowVisible && (
          <InfoWindow position={position} maxWidth={300} className="infowindow" onClose={handleMarkerClose}>
            <div className="address-column-content">
              <span className="address-column-content-title">Office Address</span>
              <p className="mapoffice">CLEARDEW HYGIENE</p>
              <p>
                No. 1635-1, Akshara Homes,
                <br />
                Annai Nagar, Thally Road,
                <br />
                Hosur, Tamil Nadu, 635109.
              </p>
            </div>
          </InfoWindow>
        )}
      </Map>
    </APIProvider>
  );
};

export default MapLocation;
