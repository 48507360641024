import React, { useState } from "react";
import "./addProduct.css";
import { addProduct } from "../../../api/productApi"; // Assume these APIs are implemented
import ListProducts from "./ListProducts";
import Modal from "../../modal/Modal";
import { FaCheckCircle } from "react-icons/fa"; // Import the success icon


export const AddProduct = () => {
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    images: null,
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(""); // To show success or error message
  const [alertType, setAlertType] = useState(""); // To differentiate between success and error
  const [previews, setPreviews] = useState([]);
  const [refreshList, setRefreshList] = useState(false); // To trigger the product list refresh
  const [isModalOpenchild, setisModalOpenchild] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    setAlertMessage("");
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    setProductData({ ...productData, images: files });

    // Generate preview URLs for each file
    const previews = files.map((file) => URL.createObjectURL(file));
    setPreviews(previews); // Update preview state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append each file to FormData
    productData.images.forEach((file) => {
      formData.append("images", file); // Use the same field name for all files
    });

    formData.append("name", productData.name);
    formData.append("description", productData.description);

    let response = await addProduct(formData, "products");

    if (response.data.statusCode === 200) {
      setAlertMessage("Product created successfully!");
      setAlertType("success");

      // Reset the form
      setProductData({
        name: "",
        description: "",
        images: null,
      });
      setPreviews(null);
      setModalOpen(false);
      setisModalOpenchild(true);
      // Trigger the product list refresh
      setRefreshList(!refreshList);
    } else {
      setisModalOpenchild(true);
      setAlertMessage("Failed to add product. Please try again.");
      setAlertType("error");
    }
  };

  const functionForhandleModal = () => {
    setisModalOpenchild(false);
  };


  return (
    <>
      <button
        onClick={openModal}
        className="open-modal-button submit-button submit-button-add"
      >
        <span>Add Product</span>
      </button>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div>
          <span className="product-entry-title">Add New Product</span>
          <form onSubmit={handleSubmit} className="product-entry-form">
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                value={productData.name}
                onChange={handleChange}
                required
                placeholder="Enter product name"
              />
            </div>
            <div className="form-group">
              <textarea
                id="description"
                name="description"
                value={productData.description}
                onChange={handleChange}
                required
                placeholder="Enter product description"
              ></textarea>
            </div>

            <div className="form-group">
              <input
                type="file"
                id="image"
                accept="image/*"
                onChange={handleImageUpload}
                multiple
                required
              />
              <div>
                {previews?.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index}`}
                    className="image-preview"
                    style={{ width: "100px", height: "100px", margin: "10px" }}
                  />
                ))}
              </div>
            </div>
            <button type="submit" className="submit-button submit-button-add">
              Submit
            </button>
          </form>
        </div>
      </Modal>

      {alertMessage && (
        <Modal isOpen={isModalOpenchild} onClose={closeModal}>
          <div className="close-btn"></div>

          {alertType == "success" && (
            <>
              <div className="success-alert">
                <FaCheckCircle className="success-icon" />
                <p className="success-message">Success</p>
              </div>
              <button onClick={functionForhandleModal} className="success-btn">
                Ok
              </button>
            </>
          )}

          {alertType == "error" && (
            <>
              <div className="success-alert">
                <FaCheckCircle className="failure-icon" />
                <p className="failure-message">Failure</p>
              </div>
              <button onClick={functionForhandleModal} className="failure-btn">
                Ok
              </button>
            </>
          )}
        </Modal>
      )}

      {/* Pass the refresh trigger to the ListProducts component */}
      <ListProducts key={refreshList}/>
    </>
  );
};
