import React, { useState, useEffect } from "react";
import "./addProduct.css";
import { fetchProductsById, updateProductById } from "../../../api/productApi"; // Assume these APIs are implemented
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../modal/Modal";
import { FaCheckCircle } from "react-icons/fa"; // Import the success icon

export const UpdateProduct = (props) => {
  const { id: productId, closeModal } = props;
  const [previews, setPreviews] = useState([]);
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    images: [],
    retainedImages: [],
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    // Fetch product details when component mounts
    const fetchProduct = async () => {
      const response = await fetchProductsById(productId, "products"); // Fetch existing product details
      console.log(response);
      setProductData({
        name: response?.data?.name,
        description: response?.data?.description,
        images: [], // Images will be managed separately
        retainedImages: response?.data?.images || [],
      });
      setPreviews(response?.data?.previewImages || []); // Display existing images
    };

    fetchProduct();
  }, [productId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setProductData({
      ...productData,
      images: [...productData.images, ...files],
    });

    // Generate preview URLs for new files
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setPreviews([...previews, ...newPreviews]);
  };

  const handleImageDelete = (index, isExistingImage) => {
    if (isExistingImage) {
      // Remove from retained images if it's an existing image
      const updatedRetainedImages = [...productData.retainedImages];
      updatedRetainedImages.splice(index, 1);
      setProductData({ ...productData, retainedImages: updatedRetainedImages });
      setPreviews([
        ...updatedRetainedImages,
        ...productData.images.map((file) => URL.createObjectURL(file)),
      ]);
    } else {
      // Remove from new images if it's a newly uploaded image
      const updatedImages = [...productData.images];
      updatedImages.splice(index - productData.retainedImages.length, 1);
      const updatedPreviews = [...previews];
      updatedPreviews.splice(index, 1);
      setProductData({ ...productData, images: updatedImages });
      setPreviews(updatedPreviews);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append each new file to FormData
    productData.images.forEach((file) => {
      formData.append("images", file);
    });

    formData.append("name", productData.name);
    formData.append("description", productData.description);
    formData.append(
      "retainedImages",
      JSON.stringify(productData.retainedImages)
    ); // Convert retained images array to JSON string

    let response = await updateProductById(productId, formData, "products"); // Update API call
    if (response.data.statusCode === 200) {
      setModalOpen(true);
      setModalContent("success");
    } else {
      setModalOpen(true);
      setModalContent("failure");
    }
  };

  const functionForhandleModal = () => {
    setModalOpen(true);
    closeModal();
  };

  return (
    <div className="product-entry-container">
      <h2 className="product-entry-title">Update Product</h2>
      <form onSubmit={handleSubmit} className="product-entry-form">
        <div className="form-group">
          <input
            type="text"
            id="name"
            name="name"
            value={productData.name}
            onChange={handleChange}
            required
            placeholder="Enter product name"
          />
        </div>
        <div className="form-group">
          <textarea
            id="description"
            name="description"
            value={productData.description}
            onChange={handleChange}
            required
            placeholder="Enter product description"
          ></textarea>
        </div>

        <div className="form-group">
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImageUpload}
            multiple
          />

          {/* Display previews with delete options */}
          <div className="preview-container">
            {previews.map((preview, index) => (
              <div key={index} className="preview-item">
                <img
                  src={preview}
                  alt={`Preview ${index}`}
                  className="image-preview"
                  style={{ width: "100px", height: "100px", margin: "10px" }}
                />

                <IconButton
                  className="delete-button"
                  onClick={() =>
                    handleImageDelete(
                      index,
                      index < productData.retainedImages.length
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
        <button type="submit" className="submit-button submit-button-add">
          Update Product
        </button>
      </form>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="close-btn"></div>

        {modalContent == "success" && (
          <>
            <div className="success-alert">
              <FaCheckCircle className="success-icon" />
              <p className="success-message">Success</p>
            </div>
            <button onClick={functionForhandleModal} className="success-btn-update">
              Ok
            </button>
          </>
        ) }
  
        {modalContent == "failure" && (
          <>
            <div className="success-alert">
              <FaCheckCircle className="failure-icon" />
              <p className="failure-message">Failure</p>
            </div>
            <button onClick={functionForhandleModal} className="failure-btn">
              Ok
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};
