import React, { useState, useEffect } from "react";
import "./listBanner.css"; // Import the corresponding CSS file
import { fetchProducts, deleteProductById } from "../../../api/productApi";
import {UpdateBanner} from "./updateBanner";
import Modal from "../../modal/Modal";

const ListBanner = (props) => {

  let {key:referesh} =props
  // Sample data for products
  const [products, setBanner] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null); // State to hold the selected product id
  const [refreshList, setRefreshList] = useState(false); // State to trigger refresh of products


  const closeModal = () =>{
    setModalOpen(false);
    setSelectedProductId(null); // Reset the selected product id when modal is closed

  } 

  // Function to handle edit action
  const handleEdit = (id) => {
    setSelectedProductId(id); // Set the selected product id
    setModalOpen(true);
  };

  useEffect(() => {
    async function apiCall() {
      let listOfBanner = await fetchProducts("banners");
      console.log("Product List::",listOfBanner?.data)
      setBanner(listOfBanner?.data);
    }
    apiCall();
  }, [referesh,refreshList]);

  // Function to handle delete action
  const handleDelete = async(id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      let response = await deleteProductById(id, "banners");
      if (response?.data?.statusCode == 200) {
        setRefreshList((prev) => !prev); // Toggle the refreshList state to trigger useEffect
      }else{
        
      }    
    }
  };

  return (
    <div className="product-list-container">
      <span className="product-list-title">Banner List</span>
      <table className="product-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product._id}>
              <td>{index + 1}</td>
              <td>{product.name}</td>
              <td>{product.description}</td>
              <td>{product.createdAt}</td>
              <td className="action-buttons">
                <button
                  onClick={() => handleEdit(product._id)}
                  className="open-modal-button submit-button submit-button-add"
                >
                  Edit
                </button>
                <button
                  className="delete-button"
                  onClick={() => handleDelete(product._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
          {products.length == 0 && (<tr className="norecords"><td colSpan={5}>No Records Found</td></tr>)}
        </tbody>
      </table>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <UpdateBanner id={selectedProductId} closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default ListBanner;
