// AboutPage.js
import React from "react";
import "./about.css"; // Ensure to import the CSS file
import aboutusImg from "../../../assets/about_contactus_top.jpg";
import { useNavigate } from "react-router-dom";


const AboutPage = () => {
  let navigate = useNavigate();
  return (
    <>
      <div class="container-aboutus">
        <div class="content-aboutus">
          <div className="order-content titleWeight">
          <p className="order-content-title">About Us</p>
          <p className="order-content-paragraph">
            Clear Dew Hygiene is committed to meeting your requirements by
            offering solutions for all of your difficult and unique needs. With
            over ten years of expertise, we are elevating India's cleaning
            standards. By devoting time and resources in comprehending the
            Indian market, Clear Dew has created fresh approaches that simplify
            daily living.
          </p>

          <button className="aboutus-btn"><a href='/about' className="alearnMore">Learn More</a></button>
          </div>
        
        </div>
        <div class="image">
          <div className="order-img">
          <img src={aboutusImg} alt="Hydration Story" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
