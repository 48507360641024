import React, { useState } from "react";
import "./contact.css"; // Make sure this file has the appropriate styles
import { FaIndustry, FaPhone, FaEnvelope } from 'react-icons/fa';
import MapLocation from "./mapLocation.js";
import { SendEmail } from "../../api/productApi.js";
import Modal from "../../components/modal/Modal.js";
import { FaCheckCircle } from "react-icons/fa"; // Import the success icon

const indianStatesAndUTs = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands", // Union Territory
  "Chandigarh", // Union Territory
  "Dadra and Nagar Haveli and Daman and Diu", // Union Territory
  "Lakshadweep", // Union Territory
  "Delhi", // Union Territory
  "Puducherry", // Union Territory
  "Ladakh", // Union Territory
  "Jammu & Kashmir", // Union Territory
];

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    country: "India",
    state: "Tamil Nadu",
    district: "",
    pincode: "",
    address: "",
  });

  const [errors, setErrors] = useState({});
  const [isModalOpenchild, setisModalOpenchild] = useState(false);
  const [alertMessage, setAlertMessage] = useState(""); // To show success or error message
  const [alertType, setAlertType] = useState(""); // To differentiate between success and error
  const closeModal = () => {
    setisModalOpenchild(false);
  };

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear error for the specific field
  };

  // Handle form submission
  const handleSubmit = async(e) => {
    e.preventDefault();

    // Simple validation
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.message) newErrors.message = "Message is required";
    if (!formData.district) newErrors.district = "District is required";
    if (!formData.pincode) newErrors.pincode = "Pincode is required";
    if (!formData.address) newErrors.address = "address is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return; // If there are errors, do not submit the form
    
    setAlertMessage("Form submitted successfully. Thanks for connecting with us !");
    setAlertType("success");
    setisModalOpenchild(!isModalOpenchild);
    // Handle form submission logic (e.g., send data to server)
    await SendEmail(formData);
      setFormData({
        name: "",
        phone: "",
        email: "",
        message: "",
        country: "India",
        state: "Tamil Nadu",
        district: "",
        pincode: "",
        address: "",
      });
  };

  const functionForhandleModal = () => {
    setisModalOpenchild(false);
  };


  return (
    <>

    <div className="contact-us-container">
      <div className="contact-form-column">
        <form onSubmit={handleSubmit} className="contact-form">
        <span className="contact-title">Contact Us</span>
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={errors.name ? "input-error" : ""}
              placeholder="Enter your name"
            />
            {errors.name && <p className="error-message">{errors.name}</p>}
          </div>
          <div className="form-group">
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={errors.phone ? "input-error" : ""}
              placeholder="Enter your phone number"
              pattern="[0-9]{10}"
              title="Please enter a 10-digit phone number"
            />
            {errors.phone && <p className="error-message">{errors.phone}</p>}
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={errors.email ? "input-error" : ""}
              placeholder="Enter your email"
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div className="form-group">
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className={errors.address ? "input-error" : ""}
              placeholder="Enter your address"
            />
            {errors.address && <p className="error-message">{errors.address}</p>}
          </div>

          <div className="form-group">
            <input
              type="text"
              id="district"
              name="district"
              onChange={handleChange}
              className={errors.district ? "input-error" : ""}
              value={formData.district}
              placeholder="Enter your district"
            />
            {errors.district && (
              <p className="error-message">{errors.district}</p>
            )}
          </div>

          <div className="form-group">
            <input
              type="text"
              id="pincode"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              className={errors.pincode ? "input-error" : ""}
              placeholder="Enter your pincode"
              pattern="[0-9]{6}"
              title="Please enter a 6-digit pincode"
            />
            {errors.pincode && (
              <p className="error-message">{errors.pincode}</p>
            )}
          </div>
          <div className="form-group">
            <select
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className={errors.state ? "input-error" : ""}
            >
              <option value="">Select State</option>
              {indianStatesAndUTs.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {errors.state && <p className="error-message">{errors.state}</p>}
          </div>

          <div className="form-group">
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              readOnly
            />
          </div>
          <div className="form-group">
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className={errors.message ? "input-error" : ""}
              placeholder="Enter your message"
            ></textarea>
            {errors.message && (
              <p className="error-message">{errors.message}</p>
            )}
          </div>
          <button type="submit" className="submit-button submit-button-contact">
            Submit
          </button>
        </form>
      </div>

      {/* Right-side content: Office and Factory Address */}
      <div className="address-column">
        <div className="address-column-main">
        <span className="address-column-title">Connect with Us</span>
        {/* <div className="address-column-child">
          <div className="address-column-icon"><FaMapMarkerAlt/></div>
          <div className="address-column-content">
            <span className="address-column-content-title">Office Address</span>
            <p>
              No. 1635-1, Akshara Homes,
              <br/>
              Annai Nagar, Thally Road,
              <br />
              Hosur, Tamil Nadu, 635109.
            </p>
          </div>
        </div> */}
        <div className="address-column-child">
          <div className="address-column-icon"><FaIndustry/></div>
          <div className="address-column-content">
            <span className="address-column-content-title">Factory Address</span>
            <p>
              CLEARDEW HYGIENE
              <br />
              Plot No.C-1, GKS Nagar, Near ITI, 
              <br/>
              Denkanikoai Road,
              <br />
              Hosur, Tamil Nadu, 635110.
            </p>
          </div>
        </div>
        <div className="address-column-child">
          <div className="address-column-icon"><FaPhone/></div>
          <div className="address-column-content">
            <span className="address-column-content-title">Phone No</span>
            <p>
              +91 9994944393 
            </p>
          </div>
        </div>
        <div className="address-column-child">
          <div className="address-column-icon"><FaEnvelope/></div>
          <div className="address-column-content">
            <span className="address-column-content-title">Email</span>
            <p>
              sales@cleardew.in
              <br />
              admin@cleardew.in
            </p>
          </div>
        </div>
      </div>
      </div>
    </div>
            <div className="mapcontainer">
            <MapLocation/>
            </div>

            {alertMessage && (
        <Modal isOpen={isModalOpenchild} onClose={closeModal}>
          <div className="close-btn"></div>

          {alertType == "success" && (
            <>
              <div className="contact-success-alert">
                <FaCheckCircle className="success-icon" />
                <p className="contact-success-message">{alertMessage}</p>
              </div>
              <div className="contact-button">
              <button onClick={functionForhandleModal} className="contact-success-btn">
                Ok
              </button>
              </div>
              
            </>
          )}

          {alertType == "error" && (
            <>
              <div className="contact-success-alert">
                <FaCheckCircle className="failure-icon" />
                <p className="contact-failure-message">Failure</p>
              </div>
              <button onClick={functionForhandleModal} className="failure-btn">
                Ok
              </button>
            </>
          )}
        </Modal>
      )}
    </>

  );
};

export default ContactUs;
