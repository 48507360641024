import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL || " http://localhost:5000/";

export async function fetchProducts(moduleRoutes) {
  const response = await fetch(`${API_URL}api/${moduleRoutes}`);
  return await response.json();
}
export async function fetchProductsById(id,moduleRoutes) {
  const response = await fetch(`${API_URL}api/${moduleRoutes}/${id}`);
  return await response.json();
}

export async function SendEmail(formData) {
  try {
    let response = await axios.post(`${API_URL}api/email`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("response::", response);
    return await response;
  } catch (error) {
    console.error("Error uploading data:", error);
  }
}

export async function addProduct(formData,moduleRoutes) {
  try {
    let response = await axios.post(`${API_URL}api/${moduleRoutes}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("response::", response);
    return await response;
  } catch (error) {
    console.error("Error uploading data:", error);
  }
}

export async function updateProductById(id,formData,moduleRoutes) {
 try {
    let response = await axios.put(`${API_URL}api/${moduleRoutes}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("response::", response);
    return await response;
  } catch (error) {
    console.error("Error uploading data:", error);
  }
}

export async function deleteProductById(id, moduleRoutes) {
  try {
    let response = await axios.delete(`${API_URL}api/${moduleRoutes}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("response::", response);
    return response;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error; // Re-throw the error if needed for further handling
  }
}