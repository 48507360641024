import React, { useRef, useEffect } from "react";
import "./productList.css"; // Your custom styles
import Rapid_C1_Can from "../../assets/Rapid_sticer/Rapid C1 can.png";
import Rapid_C2_Can from "../../assets/Rapid_sticer/Rapid C2 can.png";
import Rapid_C3_Can from "../../assets/Rapid_sticer/Rapid C3.png";
import Rapid_C5_Can from "../../assets/Rapid_sticer/Rapid C5 Lavender.png";
import Rapid_C5_Lavender_Can from "../../assets/Rapid_sticer/Rapid C5.png";
import Rapid_C6_Can from "../../assets/Rapid_sticer/Rapid C6 can.png";
import Rapid_C7_Can from "../../assets/Rapid_sticer/Rapid C7 can.png";
import Rapid_Handwash_Lemon_Can from "../../assets/Rapid_sticer/Rapid Handwash Lemon.png";
import Rapid_Hanwash_white_Can from "../../assets/Rapid_sticer/Rapid Handwash white.png";
import Rapid_Handwash_Can from "../../assets/Rapid_sticer/Rapid Handwash.png";
import Rapid_scale_Away_Can from "../../assets/Rapid_sticer/Rapid Scale Away can.png";
import Fabric_Liquid_Detergent from "../../assets/Rapid_sticer/Fabric Liquid Detergent.png";
import Kitchen_Manual_Dishwash_Liquid from "../../assets/Rapid_sticer/Kitchen Manual Dishwash Liquid.png";
import { FaMapMarkerAlt, FaIndustry, FaPhone } from "react-icons/fa";
import { GiTap } from "react-icons/gi";
import { PiToiletBold } from "react-icons/pi";
import { GiFloorPolisher } from "react-icons/gi";
import { MdOutlineTableRestaurant } from "react-icons/md";





export const products = [
  {
    id: 1,
    name: "Restroom cleaner cum Sanitizer",
    description:
      "A cutting-edge solution that combines powerful cleaning action with sanitizer properties, all without the use of harsh acids. Keep your surfaces not only impeccably clean but also germ-free, creating a hygienic environment effortlessly",
    image: Rapid_C1_Can,
    product_list: [
      "One-step cleaning and Sanitization.",
      "Product is abrasive-free and corrosive-free.",
      "Does not cause scratch marks on cleaned surfaces.",
      "Prevents buildup of lime.",
      "Removes skin grease, soap residues, and calcium soap quickly and effectively.",
      "Used in cleaning and sanitizing all surfaces in the bathroom, sink, tub, tiles, floor and fitting.",
    ],
    packSize: "pH - 5 | Pack Size - 5L",
    icons:[<GiTap/>,<PiToiletBold/>,<GiFloorPolisher/>]
  },
  {
    id: 2,
    name: "Hygienic hard surface cleaner concentrate",
    description:
      "Ultimate solution that not only cleans but also ensures a hygienic environment. With our advanced cleaning agents and effective sanitizing properties, it provides a thorough cleaning experience, leaving your floors spotless and germ-free.",
    image: Rapid_C2_Can,
    product_list: [
      "One-step cleaning and sanitation solution.",
      "Abrasive and corrosive-free.",
      "Prevents scratch marks on cleaned surfaces.",
      "Efficiently removes oil stains, dirt, and water marks.",
    ],
    packSize: "pH - 7 | Pack Size - 5L Can",
    icons:[<MdOutlineTableRestaurant/>]
  },
  {
    id: 3,
    name: "Glass cleaner Concentrated",
    description:
      "A streak-free formula to leave your windows smudge-free and crystal clear. Ideal for all glass surfaces and effortlessly removes dirt, ensuring sparkling, spotless shine.",
    image: Rapid_C3_Can,
    product_list: [
      "Excellent cleaning for smudges and finger marks.",
      "Quick action-easy spray and wipe.",
      "Clean streak-free look after use.",
      "Pleasant, fresh room care fragrance.",
    ],
    packSize: "pH - 7 | Pack size - 5L",
  },
  {
    id: 4,
    name: "Air Freshener",
    description:
      "Welcome a breath of fresh air into your space, perfect for any space where unwanted odour lingers. This solution neutralizes odors on contact, leaving behind a clean and inviting environment.",
    image: Rapid_C5_Can,
    product_list: [
      "Gives a pleasant, long-lasting fresh fragrance with a hint of musk.",
      "Removes unpleasant odour.",
      "Leaves no stain behind.",
    ],
    packSize: "pH - 7 | Pack Size - 5L Can",
  },
  {
    id: 5,
    name: "Toilet Bowl Cleaner",
    description:
      "Our effective and easy-to-use formula to tackle tough stains and eliminate bacteria, ensures sparkling clean toilet and gives a refreshing and hygienic bathroom experience.",
    image: Rapid_C6_Can,
    product_list: [
      "Prevents scaling on regular use.",
      "Removes tough stains.",
      "Fast and easy application.",
      "Ready to use product.",
      "Promote germ-free environments.",
    ],
    packSize: "pH - 1 | Pack Size - 5L Can",
  },
  {
    id: 6,
    name: "Multipuropse Cleaner",
    description:
      "A versatile neutral all-purpose cleaner to tackle various surfaces and efficiently remove dirt and grime. A perfect blend of cleanliness and captivating citrus scent that leaves your space smelling fresh.",
    image: Rapid_C7_Can,
    product_list: [
      "Material-friendly.",
      "Dries quickly without streaks.",
      "Ideal for shiny surfaces.",
      "For all water-resistant surfaces, e.g., plastic surfaces, glass surfaces, doors, windows frames and heater; water-resistant floor coverings; water-resistant materials.",
    ],
    packSize: "pH - 7 | Pack Size - 5L",
  },
  {
    id: 7,
    name: "Multipuropse Restroom Destainer",
    description:
      "Our solution to remove stubbom buildup in the kitchen Specially developed to eliminate tough limescale and mineral deposits, ensuring a spotless, residue-free shine, leaving your kitchen gleaming.",
    image: Rapid_scale_Away_Can,
    product_list: [
      "Liquid basic cleaner and lime remover based on phosphoric acid.",
      "Used for basic cleaning of sanitary areas, swimming pools, and food processing industries.",
      "Suitable for use on all acid-resistant materials and surfaces in wet areas.",
      "Ideal for basic cleaning of lime deposits on stainless steel surfaces.",
      "Suitable for removing scale in appliances like coffee makers, kettles, faucets, etc.",
    ],
    packSize: "pH-05 | Pack Size - 5L",
  },
  {
    id: 8,
    name: "Gentle Handwash Liquid Soap",
    description:
      "A perfect blend of cleanliness and hydration, embrace the soothing fragrance of lemon and indulge in the pampering care your hands deserve.",
    image: Rapid_Handwash_Can,
    product_list: [
      "Based on synthetic surfactant system, skin care ingredients, humectant, and perfume.",
      "Gives mild and effective skin cleansing.",
      "Maintains good skin condition.",
      "Used in hotels and offices.",
      "Excellent cleaning performance.",
      "Suitable for frequent hand washing.",
    ],
    packSize: "pH - 7 | Pack Size - 5L",
  },
  {
    id: 9,
    name: "Kitchen Manual Dishwash Liquid",
    description:
      "A perfect companion for sparkling dishes, specially used to cut through grease and grime.This gentle yet powerful formula leaves your dishes spotless and smelling fresh.",
    image: Kitchen_Manual_Dishwash_Liquid,
    product_list: [
      "Excellent removal and dispersion of fatty and oily matter.",
      "Effective cleaning of crockery, utensils, pots, and pans.",
      "Environment friendly.",
    ],
    packSize: "pH-8 | Pack Size - 5L",
  },
  {
    id: 10,
    name: "Fabric Liquid Detergent",
    description:
      "A convenient and effective way to achieve impeccably clean and wonderfully scented clothes in one simple step. Each capsule delivers a precise amount of detergent and a burst of delightful fragrance, ensuring a hassle-free laundry experience.",
    image: Fabric_Liquid_Detergent,
    product_list: [
      "Eco-friendly liquid detergent.",
      "Gentle on your skin and tough stains.",
      "Fabric stays soft and bright even after multiple washes.",
      "Unique Low-Foam technology ensures saving water and energy without compromising cleaning performance.",
      "Suitable for manual as well as machine washing.",
    ],
    packSize: "pH-8 | Pack size - 5L",
  },
];


const ProductListPage = () => {

  const productRefs = useRef([]); // Use useRef to store references for product cards

  // Function to scroll to the selected product
  const scrollToProduct = (index) => {
    if (productRefs.current[index]) {
      productRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div className="product-list-page">
      {/* Top 25% image */}

      <section className="top-section">
        <div className="content-overlay">
          <p>Products</p>
        </div>
      </section>

      <div className="our-Products-desc">
        <p className="our-Products-title">Our Products</p>
        <p className="products-desc">
          Cleardew Hygiene manufactures cleaning and hygiene chemicals. We
          specialize in floor cleaner, disinfecting floor cleaner, restroom and
          sanitary cleaner, glass cleaner, room fresheners, toilet bowl cleaner,
          multipurpose cleaner, descaler and lime scale remover, personal
          hygiene handwash, kitchen manual dishwasher and degreaser, and laundry
          detergents, among other things.
        </p>
      </div>

      {/* Main content */}
      <div className="main-content">
        {/* Right side: Product Names */}
        <div className="product-container">
        <div className="product-names">
          <p className="product-names-title">Products</p>
          <ul>
            {products.map((product, index) => (
              <li key={product.id}>
                <button onClick={() => scrollToProduct(index)}>
                  {product.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
        </div>


        {/* Left side: Product details (Scrollable) */}
        <div className="product-details">
          {products.map((product, index) => (
            <div
              key={product.id}
              className="product-card-productpage"
              ref={(el) => (productRefs.current[index] = el)} // Assign ref here
            >
              <div className="productpage-img">
                <div className="product-img">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="product-image"
                  />
                </div>
                {/* <div className="product-color-box">
                  <div className="box1"></div>
                  <div className="box2"></div>
                </div> */}
              </div>

              <div className="product-info-list">
                <div className="product-title-list">
                  <span>{product.name}</span>
                </div>
                <div className="product-desc">
                  <p>{product.description}</p>
                </div>
                <div className="product-desc-list">
                  <ul className="custom-list">
                    {product.product_list.map((product, index) => (
                      <li key={index}>{product}</li>
                    ))}
                  </ul>
                </div>
                <div className="product-quantity">{product.packSize}</div>
                <div className="product-icon">
                  {product?.icons?.map((item)=> 
                  <div className="icon-border">
                    {item}
                  </div>)}
              
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductListPage;
