import React from 'react';
import './modal.css'; // Include CSS for modal styling

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // Don't render modal if it's not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
