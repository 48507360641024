import React, { useState } from 'react';
import { AddProduct } from '../../admin/product/AddProduct'; // Component for adding a product
import { AddBanner } from '../../admin/banner/AddBanner';
import './dashboard.css'; // Include CSS for styling

const Dashboard = () => {

  const [activeTab, setActiveTab] = useState('add');

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="dashboard-container">
      <aside className="sidebar">
        <h3 className="sidebar-title">Admin Panel</h3>
        <ul className="sidebar-menu">
          <li
            className={`menu-item ${activeTab === 'add' ? 'active' : ''}`}
            onClick={() => handleTabChange('add')}
          >
            Product
          </li>
          <li
            className={`menu-item ${activeTab === 'addBanner' ? 'active' : ''}`}
            onClick={() => handleTabChange('addBanner')}
          >
            Banner
          </li>
        </ul>
      </aside>
      <main className="content">
        {activeTab === 'add' && <AddProduct onSuccess={() => handleTabChange('list')}/>}
        {activeTab === 'addBanner' && <AddBanner onSuccess={() => handleTabChange('listBanner')} />}
      </main>
    </div>
  );
};

export default Dashboard;
