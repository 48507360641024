import React from "react";
import { Link } from "react-router-dom";
import "./footer.css"; // Import the CSS file
import "@fortawesome/fontawesome-free/css/all.min.css";
import { products } from "../product/ProductList";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about-company">
          <p>About Company</p>
          <div>
            We are a leading provider of floor and bathroom cleaning products,
            dedicated to delivering high-quality and eco-friendly solutions to
            our customers. Our mission is to make every home and business
            cleaner and healthier.
          </div>
        </div>

        <div className="footer-section our-products">
          <p>Our Products</p>
          <ul>
            {products.map((product, index) => (
              <li key={product.id}>{product.name}</li>
            ))}
          </ul>
        </div>

        <div className="footer-section usefull-links">
          <p>Usefull Links</p>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>

        <div className="footer-section contact-us">
          <p>Contact Us</p>
          <ul>
            <li>
              Address: 
              CLEARDEW HYGIENE, 
              Plot No.C-1, GKS Nagar, Near ITI,
              Denkanikoai Road, Hosur, Tamil Nadu, 635110.
            </li>
            <li>Email: sales@cleardew.in</li>
            <li>Phone: +91 9994944393</li>
          </ul>
        </div>
        <div className="footer-section social-media">
          {/* <h4>Follow Us</h4> */}
          <div className="social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61566355441021"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://x.com/CleardewHygiene"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-x-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/cleardewhygiene/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/105114598/admin/dashboard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Cleardew. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
